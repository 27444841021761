// Variables
$customGreen: #032502;
$customBlack: #000000;
$font-sans: 'Open Sauce', system-ui, sans-serif;

header.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
  background: rgba(255, 255, 255, 0.95);
}

.navbar-logo {
  height: 50px;
  width: auto;
}

.navbar-contact-btn {
  padding: 0.5rem 1rem;
  background-color: $customGreen;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-family: $font-sans;
  font-size: 1rem;
  cursor: pointer;
}