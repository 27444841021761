// Variables (consistentes con LandingMain y LandingNavBar)
$customGreen: #032502;
$customLightGreen: #ddf7b7;
$customOffWhite: #f4f7f1;
$customBlack: #000000;
$font-sans: 'Open Sauce', system-ui, sans-serif;
$font-display: 'Agrandir Wide Medium', system-ui, sans-serif;

.footer {
  background-color: $customBlack;
  color: white;
  padding: 4rem 1rem;

  .footer-container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-logo {
    height: 40px;
    width: auto;
  }

  .footer-description {
    font-family: $font-sans;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    max-width: 250px;
  }

  .footer-socials {
    display: flex;
    gap: 1rem;
  }

  .footer-social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.3s ease;

    &:hover {
      color: white;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .footer-heading {
    font-family: $font-display;
    font-size: 1.25rem;
    font-weight: 600;
    color: white;
    margin-bottom: 1rem;
  }

  .footer-links {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .footer-link {
    font-family: $font-sans;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: $customLightGreen;
    }
  }

  .footer-contact {
    font-family: $font-sans;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.7);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .footer-contact-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .footer-icon {
    width: 16px;
    height: 16px;
    color: rgba(255, 255, 255, 0.7);
  }

  .footer-bottom {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;

    p {
      font-family: $font-sans;
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}